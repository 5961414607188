// import styling
import "../_style.scss"
import {formatNumber} from "../../../../actions/formateNumbers.actions";
import {formatDateIfNotEmpty} from "../../../../actions/date.actions";


export const CurrentFinancingCard = ({data, labels}) => {

    const carInfoLabels = labels?.carsPage?.carInfo;

    const labelMap = {
        financingType: carInfoLabels.financingType,
        financingProvider: carInfoLabels.financingProvider,
        contractPartner: carInfoLabels.contractPartner,
        contractDuration: carInfoLabels.contractDuration + " " + labels?.carsPage?.carInfo.monthsUnit,
        amountMonthlyInclTax: carInfoLabels.amountMonthly,
        amountMonthlyExclTax: carInfoLabels.amountMonthlyExclTax,
        startDt: carInfoLabels.financingContractStart,
        endDt: carInfoLabels.financingContractEnd,
        priceGross: carInfoLabels.priceGross,
        priceNet: carInfoLabels.priceNet,
        deposit: carInfoLabels.deposit,
        financedAmount: carInfoLabels.financedAmount,
        remainingValue: carInfoLabels.remainingValue,
        mileagePerYear: carInfoLabels.mileagePerYear,
        notes: carInfoLabels.notes,
    }

    function parseData(data) {
        let parsedData = { ...data }
        for (const key of Object.keys(parsedData)) {
            if (!labelMap[key]) delete parsedData[key]
        }
        parsedData.financingProvider = parsedData.financingProvider?.companyName;
        parsedData.contractPartner = parsedData.contractPartner?.companyName;
        
        return parsedData;
    }

    const parsedData = parseData(data);

    function formatData(value, key) {
        if (!value) return "-"

        const numberKeys = ['amountMonthlyInclTax', "amountMonthlyExclTax", 'priceGross', 'priceNet', 'deposit', 'financedAmount', 'remainingValue', 'mileagePerYear'];
        const dateKeys = ['startDt', 'endDt']

        if (numberKeys.includes(key)) return formatNumber(value);
        else if (dateKeys.includes(key)) return formatDateIfNotEmpty(value);
        else return value
    }

    function getRowOrder(key) {
        return Object.keys(labelMap).indexOf(key)
    }




    return (
        <div className="financing-card">
            {Object.keys(parsedData).map((key) => (
                <div className="financing-row" style={{order: getRowOrder(key)}} key={key}>
                        <span className="key">
                            {labelMap[key]}
                        </span>
                    <span className="value">
                            {formatData(parsedData[key], key)}
                        </span>

                </div>
            ))}
        </div>
    )
}
// import external
import React from "react";

// import internal slices
import {useGetFuelCardsQuery} from "../../redux/apiSlice/fuelCardApiSlice";

// import assets
import {ReactComponent as CardIcon} from "../../assets/svg/icon-active-card.svg";
import {ReactComponent as InactiveCardIcon} from "../../assets/svg/icon-inactive-card.svg";
import { ReactComponent as MagnifierIcon } from "../../assets/svg/icon-magnifier.svg";

// import react components
import LoadingComponent from "../LoadingComponent";
import {createErrorMessage} from "../AlertContainer/alert.utils";
import KeyInfoCard from "../KeyInfoCard";
import GridHandler from "../GridHandler";
import {findNewestValidEntry, formatDateIfNotEmpty} from "../../actions/date.actions";
import {dateComparator, dateNowComparator} from "../GridHandler/dates.actions";
import {Link} from "react-router-dom";
import {statusCellRenderer} from "../GridHandler/CellRenderers/statusCellRenderer";
import FilterTypes from "../GridHandler/FilterUtil/FilterTypes";
import GridTitle from "../GridHandler/GridTitle";
import {FilterButtonItem} from "../FilterButtonGroup";
import FleetWizard from "../FleetWizard";
import {FleetWizardProvider} from "../FleetWizard/FleetWizardContext";


// import internal styles
//import "./_style.scss"

/**
 * loads data for the driver overview and defines what to show
 * @param {props.labels} languageLabels - language labels used for multi-language feature
 * @param {props.title} title - title used for this section
 * @returns
 */
const fuelCardsOverview = (props) => {
    // this function is needed to convert statud id to text
    let statusIdToText= (params) => {
        if (params.data.status === 1) {
            return props.labels?.statusCode?.active
        } else {
            return props.labels?.statusCode?.inactive
        }
    }


    const excelStyles = [
        {
            id: 'string',
            dataType: 'String',
        }
    ]

    const columnDefs = [

        {
            //Link Icon to subPage Invoicedetail Page
            cellRenderer: function (params) {
                return (
                    <Link to={"/fuelcards/" + params.data.id}>
                        <MagnifierIcon className="grid-icon" alt="open row details icon"></MagnifierIcon>
                    </Link>
                );
            },
            pinned: 'left',
            resizable: false,
            sortable: false,
            filter: false,
            width: 65,
            suppressMenu: true,
            colId:"icon",
            suppressMovable:true
        },
        {
            headerName: props.labels?.fuelCardPage?.fuelCardInfo?.cardNumber, sort: 'asc',
            colId:"cardNumber",
            valueGetter: (params) => params.data.cardNumber,
            cellClass: "string",
        },
        {
            headerName: props.labels?.fuelCardPage?.fuelCardInfo?.cardName,
            colId:"cardName",
            valueGetter: (params) => params.data.cardName,
        },
        {
            headerName: props.labels?.fuelCardPage?.fuelCardInfo?.status,
            colId:"status",
            valueGetter: (params) => params.data.status,
            valueFormatter:(params) =>statusIdToText(params),
            filterValueGetter: (params) =>statusIdToText(params),
            cellRenderer:statusCellRenderer,
            cellRendererParams: {
                positiveText:1, 
                negativeText:9,
             }
        },
        {
            headerName: props.labels?.fuelCardPage?.fuelCardInfo?.expirationDate,
            colId:"expirationDate",
            valueGetter: (params) => formatDateIfNotEmpty(params.data.expirationDate),
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: props.labels?.fuelCardPage?.fuelCardInfo?.energyProvider,
            colId:"energyProvider",
            valueGetter: (params) => params.data.provider?.companyName,
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.VIN,
            colId:"VIN",
            valueGetter: function (params) {
                let newestCarId = findNewestValidEntry(params?.data?.cars, "startDate", "endDate");
                const newestCar = params.data.cars[newestCarId]
                return newestCar?.vin
            },
            cellRenderer: (params) => {
                // If params.value is empty, show nothing
                if (!params.value) return null
                return (
                    <Link to={"/cars/" + params.value}>
                        <span className="link-with-icon">
                            {params.value}
                        </span>
                    </Link>
                )
            }
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.plateNumber,
            colId:"plateNumber",
            valueGetter: function (params) {
                let newestCarId = findNewestValidEntry(params?.data?.cars, "startDate", "endDate");
                const newestCar = params.data.cars[newestCarId]
                return newestCar?.licencePlate
            }
        },
        {
            headerName: props.labels?.fuelCardPage?.fuelCardInfo?.carCardLinkStart,
            colId:"carCardLinkStart",
            valueGetter: function (params) {
                let newestCarId = findNewestValidEntry(params?.data?.cars, "startDate", "endDate");
                const newestCar = params.data.cars[newestCarId]
                return formatDateIfNotEmpty(newestCar?.startDate)
            }
        },
        {
            headerName: props.labels?.fuelCardPage?.fuelCardInfo?.carCardLinkEnd,
            colId:"carCardLinkEnd",
            valueGetter: function (params) {
                let newestCarId = findNewestValidEntry(params?.data?.cars, "startDate", "endDate");
                const newestCar = params.data.cars[newestCarId]
                return formatDateIfNotEmpty(newestCar?.endDate)
            }
        },
        // {
        //     headerName: props.labels?.driverPage?.driverInfo?.driver,
        //     valueGetter: function (params) {
        //         let newestDriverId = findNewestValidEntry(params?.data?.customers, "startDate", "endDate");
        //         const newestDriver = params.data.customers[newestDriverId]
        //         return newestDriver?.firstName + " " + newestDriver?.lastName
        //     }
        // },
        // {
        //     headerName: props.labels?.fuelCardPage?.fuelCardInfo?.driverCardLinkStart,
        //     valueGetter: function (params) {
        //         let newestDriverId = findNewestValidEntry(params?.data?.customers, "startDate", "endDate");
        //         const newestDriver = params.data.customers[newestDriverId]
        //         return formatDateIfNotEmpty(newestDriver?.startDate)
        //     }
        // },
        // {
        //     headerName: props.labels?.fuelCardPage?.fuelCardInfo?.driverCardLinkEnd,
        //     valueGetter: function (params) {
        //         let newestDriverId = findNewestValidEntry(params?.data?.customers, "startDate", "endDate");
        //         const newestDriver = params.data.customers[newestDriverId]
        //         return formatDateIfNotEmpty(newestDriver?.endDate)
        //     }
        // }
    ];
    // load cars from endpoint
    const {
        data: cards,
        isLoading,
        isSuccess,
        isError,
        error
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useGetFuelCardsQuery();

    let content;
    // show loading text (spinner) while loading
    if (isLoading) {
        content = <LoadingComponent show={true}></LoadingComponent>;
    } else if (isSuccess) { // handle successful data loading
        // show grid with the data
        if (cards && cards?.length > 0) {
            let numActiveCards = cards.filter(card => card?.status === 1)?.length;
            let numNonActiveCards = cards.filter(card => card?.status !== 1)?.length;
            content = (
                <>
                    <div style={{display: "flex", justifyContent: "space-between", columnGap:"10px", alignItems:"center", flexWrap:"wrap"}}>
                        <h1>{props.title}</h1>
                        <FleetWizardProvider>
                            <FleetWizard
                                initialTab={"fuelcard"}
                                labels={props?.labels}
                                hideTabs={true}
                            >
                                {props?.labels?.fleetWizard?.titles?.addFuelCard}
                            </FleetWizard>
                        </FleetWizardProvider>
                    </div>

                    <div className="number-cards-container">
                        <KeyInfoCard title={props.labels?.fuelCardPage?.keyNumbers?.active}
                                     number={numActiveCards}
                                     cardStyle={"card-positive"}
                                     unit={""}
                        >
                            <CardIcon className={"card-icon"}></CardIcon>
                        </KeyInfoCard>
    
                        <KeyInfoCard title={props.labels?.fuelCardPage?.keyNumbers?.nonActive}
                                     number={numNonActiveCards}
                                     cardStyle={"card-neutral"}
                                     unit={""}
                        >
                            <InactiveCardIcon className={"card-icon"}></InactiveCardIcon>
                        </KeyInfoCard>
                    </div>
                    <GridHandler
                        gridTitle={
                            <GridTitle
                                title={props.labels?.fuelCardPage?.titles?.fuelCardTableTitle}
                                subtitle={props.labels?.fuelCardPage?.titles?.fuelCardTableSubtitle}
                                badgeText={cards.length + " " + props.labels?.fuelCardPage?.titles?.fuelCards}
                            />}
                        excelStyles={excelStyles}
                        data={cards}
                        columnDefs={columnDefs}
                        downloadable={true}
                        gridName={`upto_fuelcards_overview`}
                        fileName={`upto_${props.title}`}
                        downloadTextCsv={props.labels.otherElements.downloadCsv}
                        downloadTextExcel={props.labels.otherElements.downloadExcel}
                        filterItems={[
                            <FilterButtonItem id={0} text={props.labels.fuelCardPage?.filter?.all} filterType={FilterTypes.ALL}/>,
                            <FilterButtonItem id={1} text={props.labels.fuelCardPage?.filter?.activeOnly} filterType={FilterTypes.FUELCARDS_ONLY_ACTIVE}/>,
                        ]}
                        defaultFilterType={FilterTypes.FUELCARDS_ONLY_ACTIVE}
                    ></GridHandler>
                </>
            )
        }else {
            content = createErrorMessage(error, props.title, "h1", props.labels);
        }
    } else if (isError) {
        // show different error messages based on status code
        content = createErrorMessage(error, props.title, "h1", props.labels);
    }
    return (
        <>
            {content}
        </>
    )
}

export default fuelCardsOverview